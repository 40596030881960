import _ from 'lodash'

export const backendErrors = Object.freeze({
  integration: {
    rts: {
      account_binding_not_found: 'integration.rts.account_binding_not_found'
    }
  }
})

// TODO: move to tests
if (process.env.NODE_ENV === 'development') {
  class ConfigError extends Error {
    constructor(message) {
      super(message)
      this.name = 'ConfigError'
    }
  }

  /* eslint-disable no-inner-declarations */
  function walkDeep(object, path = []) {
    Object.entries(object).forEach(([key, value]) => {
      if (key.includes('.')) {
        throw new ConfigError('Property key must not contain points')
      }

      const valuePath = [...path, key]

      if (_.isPlainObject(value)) {
        walkDeep(value, valuePath)
      } else if (value !== valuePath.join('.')) {
        throw new ConfigError('Path to property should be equal to property value')
      }
    })
  }

  walkDeep(backendErrors)
  /* eslint-enable no-inner-declarations */
}
