<template>
  <div class="user-login d-flex justify-center">
    <div class="content-container d-flex flex-column">
      <div class="page-title text-center">Вход в систему Аквавосток</div>

      <v-sheet class="d-flex mt-8 px-12 py-11 justify-space-between align-center" :elevation="0">
        <v-img
          class="flex-grow-0 flex-shrink-1"
          :src="require('@/assets/rts_logo.svg')"
          :height="44"
          position="left center"
          contain
        />

        <v-btn
          class="text--white"
          :href="rtsLoginUrl"
          rel="noreferrer noopener"
          :disabled="isPending || pendingTasks.checkRoute"
          :loading="pendingTasks.checkRoute"
          large
          depressed
          color="rtsPrimary"
        >
          Войти через РТС-тендер
        </v-btn>
      </v-sheet>

      <v-sheet class="credentials-login mt-4 px-12 py-6" :elevation="0">
        <v-expansion-panels flat>
          <v-expansion-panel>
            <v-expansion-panel-header class="pa-0 text-base text--secondary">
              Войти по почте и паролю
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <template v-if="isPasswordResetting" class="d-flex flex-column">
                <div class="d-flex align-center font-weight-bold">
                  <v-btn icon @click="cancelResetPassword()">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  <span class="ml-2">Сброс пароля</span>
                </div>

                <div v-if="isPasswordResetEmailSent" class="success--text mt-8 text-sm">
                  На вашу почту выслано письмо для восстановления пароля
                </div>

                <template v-else>
                  <v-form ref="resetPasswordForm" class="mt-8" :disabled="isPending">
                    <v-text-field
                      id="login"
                      v-model="resetPasswordFormModel.login"
                      type="email"
                      autocomplete="username"
                      label="Почта"
                      :rules="formRules.login"
                      dense
                    />
                  </v-form>

                  <v-btn class="w-100 mt-8" :loading="isPending" outlined color="#5b6670" @click="resetPassword()">
                    Отправить ссылку на почту
                  </v-btn>
                </template>
              </template>

              <template v-else>
                <div class="text-sm warning--text">
                  Вам не будут доступны функции РТС-тендер при авторизации по почте и паролю
                </div>

                <v-form ref="loginForm" class="mt-4" :disabled="isPending">
                  <v-text-field
                    id="login"
                    v-model="formModel.login"
                    type="email"
                    autocomplete="username"
                    label="Почта"
                    :rules="formRules.login"
                  />

                  <password-input
                    id="password"
                    v-model="formModel.password"
                    autocomplete="current-password"
                    label="Пароль"
                    :rules="formRules.password"
                    @keyup.native.enter="login()"
                  />
                </v-form>

                <div class="d-flex align-center mt-8 w-100">
                  <div class="d-flex flex-column">
                    <!-- TODO -->
                    <text-btn @click="handleResetPassword()">Сбросить пароль</text-btn>
                    <router-link class="text-decoration-none" to="/registration">Зарегистрироваться</router-link>
                  </div>

                  <v-btn
                    class="flex-grow-1 ml-8"
                    :disabled="isPending"
                    :loading="isPending"
                    outlined
                    color="primary"
                    large
                    @click="login()"
                  >
                    Войти
                  </v-btn>
                </div>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { createQueryString } from 'geoportal/src/utils/common'
  import { RequestError } from 'geoportal/src/utils/errors'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import i18n from '@/i18n'
  import { backendErrors } from '@/configurations/backendErrors'
  import { RegistrationStep } from '@/views/UserRegistrationView/userRegistration'

  const formRules = {
    login: [(value) => !!value || i18n.t('auth.errors.usernameIsRequired')],
    password: [(value) => !!value || i18n.t('auth.errors.passwordIsRequired')]
  }

  export default {
    name: 'UserLoginView',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        formRules,

        isPasswordResetEmailSent: false,

        resetPasswordFormModel: {
          login: undefined
        },
        isPasswordResetting: false,

        formModel: {
          login: undefined,
          password: undefined
        }
      }
    },

    computed: {
      rtsLoginUrl() {
        return this.$auth.getRtsLoginUrl()
      }
    },

    watch: {
      $route: {
        handler: 'checkRoute',
        immediate: true
      }
    },

    methods: {
      async handleResetPassword() {
        this.isPasswordResetting = true

        await this.$nextTick()
        this.$refs.resetPasswordForm && this.$refs.resetPasswordForm.resetValidation()
      },

      async cancelResetPassword() {
        this.isPasswordResetting = false
        this.isPasswordResetEmailSent = false
      },

      @handlePendingTask()
      async resetPassword() {
        if (!this.$refs.resetPasswordForm.validate()) {
          return
        }

        await this.$api.backend.users.send_reset_password_code({ login: this.resetPasswordFormModel.login })
        this.isPasswordResetEmailSent = true
      },

      @handlePendingTask()
      async login() {
        if (!this.$refs.loginForm.validate()) {
          return
        }

        const { login, password } = this.formModel
        await this.$auth.login(login, password)
      },

      @handlePendingTask('checkRoute')
      async checkRoute() {
        if (this.$auth.authenticated) {
          let success

          try {
            success = await this.$auth.checkIfWasBoundToRts()
          } catch (e) {
            this.$router.replace('/')
            throw e
          }

          if (!success) {
            this.$router.goToForbiddenErrorPage()
          }

          return
        }

        const rtsAuthorizationCode = this.$route.query.code
        if (!rtsAuthorizationCode) {
          return
        }

        this.$router.replace({ query: null })

        try {
          await this.$api.backend.rts.login({ authorization_code: rtsAuthorizationCode })
          await this.$auth.afterLogin()
        } catch (e) {
          if (!(e instanceof RequestError)) {
            throw e
          }

          const result = e.original?.response?.data
          if (result?.code !== backendErrors.integration.rts.account_binding_not_found) {
            throw e
          }

          this.$router.push(
            `/registration/${RegistrationStep.USER_DATA}?${createQueryString(
              _.pick(result.data, ['registration_code', 'email'])
            )}`
          )
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-login {
    .content-container {
      margin-top: 128px;
      margin-bottom: 32px;
      width: 520px;

      .credentials-login {
        .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
          padding: 0;
        }
      }
    }
  }
</style>
